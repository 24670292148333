@import './reset';

.sr-only {
  position: absolute;
  left: -1000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.button-link {
  text-align: left;
  font-size: inherit;
  color: #0f306b;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    text-decoration-thickness: 2px;
  }
}
